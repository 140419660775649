import {http, param} from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'

export const API_ROOT = `${conf.API_URL}`

const LocationService = {
    findLocation(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status ?  (payload.status.trim() == 'Not Covered' ? 'NotCovered' : (payload.status.trim() == 'not covered' ? 'NotCovered'  : payload.status.trim())) : '' },
            { query: 'filterModel', val: payload.filterModel ?  encodeURIComponent(JSON.stringify(payload.filterModel))  : null },
        ]
        return http.get(`${API_ROOT}/supplier/locations${param(arr)}`).then(resp => {
            return resp
        })
    },
    findproviderLocationstatus(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim()) :(payload.idn ? encodeURIComponent(payload.idn.trim()):'')},
            { query: 'proOrgId', val: payload.proOrgId ? payload.proOrgId:0},
            { query: 'status', val: payload.status ? payload.status.trim() : '' },

        ]
        return http.get(`${API_ROOT}/supplier/locations/providerLocation${param(arr)}`).then(resp => {
            return resp
        })
    },
    getLocation(name) {
        return http.get(`${API_ROOT}/supplier/locations/${name}`).then(resp => {
            return resp
        })
    },
    createLocation(payload) {
        return http.post(`${API_ROOT}/supplier/locations`, payload).then(resp => {
            return resp
        })
    },
    updateLocation(payload) {
        return http.put(`${API_ROOT}/supplier/locations/${payload.location_id}`, payload).then(resp => {
            return resp
        })
    },
    deleteLocation(location_id) {
        return http.delete(`${API_ROOT}/supplier/locations/${location_id}`).then(resp => {
            return resp
        })
    },
    downloadSupplierLocationReportReport(extenstionType) {
        return http.get(`${API_ROOT}/supplier/locations/LocationReport/${extenstionType}`,{ responseType: 'blob' }).then(resp => {

            fileDownload(resp.data, `Location Report.${extenstionType}`,'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
    uploadFile (formData) {
        return http.post(`${API_ROOT}/supplier/locations/upload-location-file`, formData).then(resp => {
            return resp
        })
    },
    getSearchMeta () {
        return http.get(`${API_ROOT}/supplier/locations/search-meta`).then(resp => {
            return resp
        })
    },

    //location File
    findLocationFile(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' }
        ]
        return http.get(`${API_ROOT}/supplier/locations/location-files${param(arr)}`).then(resp => {
            return resp
        })
    },
    getLocationFile(id) {
        return http.get(`${API_ROOT}/supplier/locations/location-files/${id}`).then(resp => {
            return resp
        })
    },
    findSupplierLocationReport(payload) {
        return http.get(`${API_ROOT}/supplier/locations/SupplierLocationReport/`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data,'Supplier Location Report.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },

    findSupplierLocationCSVReport(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'status', val: payload.status },
        ]
        return http.get(`${API_ROOT}/supplier/locations/SupplierLocationReport/csv/${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            fileDownload(resp.data, 'Supplier Location Report.csv','text/csv')
        })
    },
}

export default LocationService
