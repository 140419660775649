import LocationModal from '../location/LocationModal'
import locationService from '../../../services/supplier/location'

export default {
    components: {
        LocationModal
    },
    data() {
        return {
            total: 0,
            items: [],
            listIDN: [],
            search: {
                idn: null,
                status: null,
                proOrgId: 0
            },
            searchQuery: {},
            header: [
                {
                    label: 'IDN/Provider Name',
                    name: 'idn',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Verified',
                    name: 'progress',
                    sort: false,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left'
                },
                {
                    label: 'Notisphere Provider',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                },
                {
                    label: 'Last Update',
                    name: 'LastChangeAt',
                    sort: true,
                    row_text_alignment: 'text-center',
                    column_text_alignment: 'text-center'
                }
            ],

            locationModalKey: 0,

            listStatus: [{ text: 'Yes', value: 'Supported' }, { text: 'No', value: 'Unsupported' }],
        }
    },
    mounted() {
        this.getSearchMeta()
    },
    methods: {
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            locationService.findproviderLocationstatus({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }).then(resp => {
                if (!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        getSearchMeta() {
            locationService.getSearchMeta().then(resp => {
                if (!resp.error) {
                    var data = resp.data.d
                    this.listIDN = [
                        ...data.idn.map(x => { return {text: x, value: x} })
                    ]
                    this.listCity = [
                        {text: 'All', value: null},
                        ...data.city.map(x => { return {text: x, value: x} })
                    ]
                    this.listState = [
                        {text: 'All', value: null},
                        ...data.state.map(x => { return {text: x, value: x} })
                    ]
                }
            })
        },
    }
}